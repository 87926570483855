<template>
  <div>
    <div class="serviceRight" :style="{ right: offset }">
      <div class="serviceMain">
        <div class="close_ser" v-if="showElement" @click="closeSers"></div>
        <div
          class="close_ser open_ser"
          v-if="!showElement"
          @click="openServices"
        ></div>
        <h3 class="title">客服中心</h3>
        <div
          class="ser_item qq_service"
          v-for="(item, index) in kefuList"
          :key="index"
        >
          <p class="ser_tit">
            官方{{ item.type == "1" ? "企业微信" : "QQ" }}客服
          </p>
          <a target="_blank">
            <div class="wx_item">
              <!-- <img :src="item.img" :alt="item.type"> -->
              <Image-Preview :src="item.codeUrl" alt="item.type" />
              <p>{{ item.nub }}</p>
            </div>
          </a>
        </div>
      </div>
      <div class="feaDownloaded">
        <!-- <a
          :href="origin + '/system/verdata/download?ips=' + hostname"
          target="_blank"
        > -->
        <a @click="handleLinkClick" target="_blank">
          <div class="fea_box_down"></div>
        </a>
      </div>
    </div>

    <!-- <div class="serviceRight" :style="{ left: offset }">
      <div class="serviceMain">
       
        <h3 class="title">活动大厅</h3>
        <div
          style="padding-top: 10px"
          class="ser_item qq_service"
          v-for="(item, index) in kefuList"
          :key="index"
        >
          
          <a :href="'/news?pid=' + $route.query.pid">
            <div class="wx_item">
             
              <Image-Preview
                src="https://ts1.cn.mm.bing.net/th/id/R-C.d97204b5bd1ec10763847c3b317420c2?rik=DOCuqWEaJykxng&riu=http%3a%2f%2fbpic.588ku.com%2felement_origin_min_pic%2f19%2f03%2f06%2f10283cca10c2cf58aca02382f997bcd8.jpg&ehk=64AeiqKR%2bElZ98GE6bBRIgNxklXBY6%2fHZ4XNvt6v9A4%3d&risl=&pid=ImgRaw&r=0"
                alt="item.type"
              />
           
            </div>
          </a>
        </div>
      </div>
      <div class="feaDownloaded">
      
        <a @click="handleLinkClick" target="_blank">
          <div class="fea_box_down"></div>
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
import { get } from "../utils/http";
export default {
  data() {
    return {
      showElement: true,
      offset: "-220px",
      kefuList: [],
      origin: null,
      hostname: null,
    };
  },
  created() {
    this.origin = window.location.origin + process.env.VUE_APP_BASE_API;
    this.hostname = window.location.hostname;
    this.getKefuList();
  },
  methods: {
    handleLinkClick(event) {
      event.preventDefault(); // 取消默认的点击事件
      get("/system/verdata/download?ips=" + this.hostname)
        .then((res) => {
          // console.log(res);
          window.open(res.msg);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getKefuList() {
      get("/system/kf/kefu")
        .then((res) => {
          //console.log(res);
          this.kefuList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeSers() {
      this.showElement = false;
      this.offset = "0px";
    },
    openServices() {
      this.showElement = true;
      this.offset = "-220px";
    },
  },
};
</script>

<style lang="less" scoped>
.serviceRight .close_ser {
  left: -8px;
  background: url("../assets/img/kefu/close2.png") no-repeat center center/100%
    100%;
}

.no_show {
  display: none;
}

.yes_show {
  display: block;
}

.serviceRight {
  display: block;
  position: fixed;
  right: -10px;
  top: 35%;
  width: 250px;
  z-index: 110;
  transition: all 0.5s;

  .feaDownloaded {
    .fea_box_down {
      width: 131px;
      height: 70px;
      margin: 0 auto;
      background: url("../assets/img/kefu/download.png") no-repeat center/100%
        100%;
    }
  }

  .serviceMain {
    padding-top: 1.8rem;
    padding-bottom: 0.5rem;
    background: url("../assets/img/kefu/service-bg.png") no-repeat center
      center/100% 100%;

    .ser_item {
      width: 150px;
      max-height: 202px;
      background: #3d2a14;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      overflow-y: scroll;

      .wx_item {
        padding: 0 0.6rem;
        margin-bottom: 0.5rem;
        text-align: center;

        img {
          vertical-align: middle;
          border-style: none;
          max-width: 100%;
          transition: all 0.8s;
        }

        p {
          line-height: 1;
          font-size: 16px;
          color: #be9c74;
          /* margin-left: 0.5rem; */
          margin-top: 0.2rem;
          text-align: center;
          margin-bottom: 0;
        }
      }

      a {
        color: #ffd9ab;
        text-decoration: none;
        background-color: transparent;
        transition: all 0.6s;
      }

      .ser_tit {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #e9c38f;
        line-height: 30px;
      }
    }

    .title {
      // margin-bottom: 0.8rem;
      margin: 10px;
      text-align: center;
      font-size: 26px;
      font-family: FZSongKeBenXiuKaiS-R-GB;
      font-weight: 800;
      color: #33220f;
      line-height: 20px;
      text-shadow: 0px 2px 2px rgba(58, 36, 15, 0.38);
      // margin-block-start: 1em;
      // margin-block-end: 1em;
      // margin-inline-start: 0px;
      // margin-inline-end: 0px;
    }

    .close_ser {
      position: absolute;
      // left: px;
      top: 1.5rem;
      width: 30px;
      height: 104px;
      cursor: pointer;
      background: url("../assets/img/kefu/close.png") no-repeat center
        center/100% 100%;
    }

    .open_ser {
      background: url("../assets/img/kefu/close2.png") no-repeat center
        center/100% 100%;
    }
  }
}
</style>