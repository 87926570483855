import axios from "axios";
import { Message } from 'element-ui';
import { getToken, removeToken } from "./auth";
import { Base64 } from 'js-base64';
import { MD5 } from 'crypto-js';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000
})
//当前时间戳转换成16进制字符串
let timesCha = Number(localStorage.getItem('servetimeCha')) || 0;
const timenow = Date.now() / 1000 + timesCha
let timestamp = Math.floor(timenow).toString(16)
let key = MD5(timenow).toString() + timestamp;
let key1 = key.substring(8)
let key64 = Base64.encode(key1);
// 添加请求拦截器
service.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers.GGbond = key64
    return config;
}, (error) => {
    return Promise.reject(error);
});




//响应拦截器
service.interceptors.response.use((response) => {
    const res = response.data;
    // console.log(res);
    if (res.code !== 200) {
        Message({
            type: "error",
            message: res.msg || "请求错误"
        })
        // return Promise.reject(new Error(res.msg))
        return Promise.reject(res)
    } else {
        if (res.nickname) {
            Message({
                type: "success",
                message: res.msg || "请求成功"
            })
        }
        return res
    }
},
    function (error) {
        Message({
            type: "error",
            message: error.msg || "请求错误"
        })
        return Promise.reject(error)
    }
)

export default service